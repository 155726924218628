<template>
  <div class="detailed-return">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <navigator :items="navigate_items"></navigator>
        </div>
        <div class="col-12">
          <div class="card card-shadow">
            <div class="card-body">
              <b-table
                hover
                no-local-sorting
                :fields="fields"
                :items="data"
                :per-page="0"
                :current-page="currentPage"
              >
                <template v-slot:cell(code)="data">{{
                  formatCode(data.value)
                }}</template>
              </b-table>
              <div class="no-data" v-if="data.length == 0">
                <span>Sem dados</span>
              </div>
              <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helper from "@/imports/Helpers";
import { bus } from "@/main";
import return_codes from "@/imports/ReturnCodes";

export default {
  name: "detailed-return",
  props: {
    id: Number
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      rows: 0,
      data: [],
      filter: {
        name: ""
      },
      includes: ["shipmentReturn", "client"],
      sortBy: "",
      filterTerm: "",
      fields: [
        {
          key: "client.name",
          label: "Cliente",
          class: "client-name-cell",
          sortable: true
        },
        {
          key: "shipment_return.name",
          label: "Retorno",
          class: "return-cell",
          sortable: true
        },
        {
          key: "consumer_unit",
          label: "Unidade Consumidora",
          class: "consumer_unit-cell"
        },

        { key: "code", label: "Código", class: "code-cell", sortable: true }
      ],
      navigate_items: [
        {
          text: "Retornos",
          route: "dashboard.returns"
        },
        {
          text: "Detalhado",
          active: true
        }
      ]
    };
  },
  watch: {
    currentPage: {
      handler: function(value) {
        this.providerData();
      }
    }
  },
  mounted() {
    this.filter.filter = this.id;
    this.providerData();
  },
  methods: {
    providerData() {
      helper
        .getPaginateQuery(
          "shipments-clients",
          helper.getUrlBuildQuery(
            this.perPage,
            this.currentPage,
            this.filter,
            this.includes,
            this.sortBy
          )
        )
        .then(response => {
          this.rows = response.data.total;
          this.data = response.data.data;
        });
    },
    formatCode: function(data) {
      for (var code of return_codes.codes) {
        if (code.code == data) {
          return code.text;
        }
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/styles/general.scss";
.list-clients {
  .name-cell {
    width: 40%;
  }
  .cpf-cell {
    width: 20%;
  }
  .phone-cell {
    width: 20%;
  }
  .date-cell {
    width: 20%;
  }
}
</style>